<template>
  <div class="avatar">
    <div class="section main">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/avatar/img_main.png" />
        </div>
        <div class="text">
          <h1>{{ this.$t("avatar.intro_main_header") }}</h1>
          <p>{{ this.$t("avatar.intro_main_desc1") }}</p>
          <p>{{ this.$t("avatar.intro_main_desc2") }}</p>
        </div>
      </div>
    </div>

    <div class="section configure">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/avatar/img_configure_01.jpg" />
          <img src="@/assets/img/avatar/img_configure_02.png" />
        </div>
        <div class="text">
          <h1>{{ this.$t("avatar.intro_configure_header") }}</h1>
          <p>{{ this.$t("avatar.intro_configure_desc1") }}</p>
          <p>{{ this.$t("avatar.intro_configure_desc2") }}</p>
        </div>
      </div>
    </div>

    <div class="section look">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/avatar/img_codi.png" />
        </div>
        <div class="text">
          <h1>{{ this.$t("avatar.intro_codi_header") }}</h1>
          <p>{{ this.$t("avatar.intro_codi_desc1") }}</p>
          <p>{{ this.$t("avatar.intro_codi_desc2") }}</p>
        </div>
      </div>
    </div>

    <div class="section nft">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/avatar/img_nft.jpg" />
        </div>
        <div class="text">
          <h1>{{ this.$t("avatar.intro_nft_header") }}</h1>
          <p>{{ this.$t("avatar.intro_nft_desc1") }}</p>
          <p>{{ this.$t("avatar.intro_nft_desc2") }}</p>
        </div>
      </div>
    </div>

    <div class="banner">
      <!-- <img src="@/assets/img/avatar/img_nft_set.png" /> -->
      <div class="inner"></div>
      <div class="layer"></div>
    </div>

    <div class="section faq">
      <div class="inner">
        <div class="image">
          <img src="@/assets/img/avatar/img_faq.png" />
        </div>
        <div class="text">
          <h1>{{ this.$t("avatar.intro_faq_header") }}</h1>
          <p>{{ this.$t("avatar.intro_faq_desc1") }}</p>
          <p>{{ this.$t("avatar.intro_faq_desc2") }}</p>
          <button
            class="button red compact"
            @click="this.$router.push('/cs/faq')"
          >
            {{ this.$t("avatar.intro_faq_button") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Avatar",
};
</script>

<style scoped>
.avatar {
  position: relative;
  color: #000;

  word-break: keep-all;
}
.avatar > .section {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.avatar > .section > .inner {
  position: relative;
  width: 1200px;
  max-width: 100%;
  min-height: 560px;
  margin: 0 auto;
  padding: 6rem 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.avatar > .section > .inner > .image {
  position: relative;
  width: 50%;
}
.avatar > .section > .inner > .image > img {
  max-width: 100%;
}
.avatar > .section > .inner > .text {
  width: 50%;
  padding: 0 2rem;
}
.avatar > .section > .inner > .text > h1 {
  font-size: 2.8rem;
  font-weight: 700;
  padding: 0;
  margin: 0 0 2rem 0;
}
.avatar > .section > .inner > .text > p {
  font-size: 2rem;
  font-weight: 400;
  padding: 0;
  margin: 0 0 0.8rem 0;
}

.avatar > .section.main {
  background-image: url("../../assets/img/avatar/img_main_back.jpg");
}
.avatar > .section.main > .inner > .image > img {
  display: block;
  margin: 0 auto;
  width: 400px;
}

.avatar > .section.configure {
  background-color: #fff;
}
.avatar > .section.configure > .inner {
  flex-direction: column-reverse;
  flex-wrap: wrap;
}
.avatar > .section.configure > .inner > .text {
  width: 100%;
  margin-bottom: 3rem;
}
.avatar > .section.configure > .inner > .image {
  width: 100%;
  text-align: center;
}
.avatar > .section.configure > .inner > .image > img {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 0.8rem);
  margin: 0.4rem;
}

.avatar > .section.look {
  background-color: #fff1d7;
}
.avatar > .section.look > .inner {
  padding: 0 1rem;
}
.avatar > .section.look > .inner > .image {
  align-self: stretch;
  display: flex;
  align-items: flex-end;
}
.avatar > .section.look > .inner > .image > img {
  display: block;
  margin: 0 auto;
  width: 460px;
}

.avatar > .section.nft {
  background-color: #fff;
}
.avatar > .section.nft > .inner {
  flex-direction: row-reverse;
}
.avatar > .section.nft > .inner > .image > img {
  display: block;
  width: 320px;
  max-width: 100%;
  margin: 0 auto;
}

.avatar > .banner {
  position: relative;
  height: 400px;
  background-image: url("../../assets/img/avatar/img_nft_back.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;

  overflow: hidden;
}
.avatar > .banner > .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;

  background-image: url("../../assets/img/avatar/img_nft_set.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  background-attachment: fixed;
}

.avatar > .banner > .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/img/avatar/img_nft_layer.png");
  background-repeat: repeat-x;
  background-size: cover;
  background-attachment: fixed;

  overflow: hidden;
}

.avatar > .section.faq {
  background: #fff;
}
.avatar > .section.faq > .inner > .image > img {
  display: block;
  width: 90%;
  margin: 0 auto;
}
.avatar > .section.faq > .inner > .text > button {
  font-size: 1.4rem;
  border-radius: 3rem;
  margin: 2rem 0 0 0;
  padding: 1.2rem 2.4rem;
}

@media (max-width: 800px) {
  .avatar > .section > .inner {
    padding: 4rem 1rem;
  }
  .avatar > .section > .inner > .image {
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .avatar > .section > .inner > .text {
    width: 100%;
  }
  .avatar > .section > .inner > .text > h1 {
    font-size: 2.4rem;
  }
  .avatar > .section > .inner > .text > p {
    font-size: 1.6rem;
  }

  .avatar > .section.configure > .inner > .image > img {
    display: block;
    width: calc(100% - 0.8rem);
    margin: 0.4rem;
  }
}
</style>
